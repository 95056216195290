<template>
  <el-container class="main">
    <el-header class="header">
      <div class="logo">
        <img src="../assets/logo_head.png" alt srcset />
      </div>
      <div class="user">
        <el-popover placement="bottom" ref="popoverRef" trigger="click" width="500" v-model="taskVisible">
          <template>
            <div class="close-btn">
              <el-button type="text" class="btn" style="margin-right:10px;" @click="allRead()">全部已读</el-button>
              <el-button type="text" class="btn" @click="taskVisible = false">关闭</el-button>
            </div>
              
            <el-tabs v-model="activeName">
              <el-tab-pane label="任务提醒" name="first">
                <div v-if="taskValue==0" class="no-message">暂无任务提醒</div>
                <div v-else>
                  <div v-if="taskList[0]" class="task-reminder">
                    <div>
                      <div class="task-title">{{taskList[0].typeStr}}</div>
                      <div class="task-content">{{taskList[0].content}}</div>
                      <div class="task-time">{{taskList[0].createdDate}}</div>
                    </div>
                    <el-button type="text" class="task-btn" @click="goDeal(taskList[0])">前往处理</el-button>
                  </div>
                  <div v-if="taskList[1]" class="task-reminder">
                    <div>
                      <div class="task-title">{{taskList[1].typeStr}}</div>
                      <div class="task-content">{{taskList[1].content}}</div>
                      <div class="task-time">{{taskList[1].createdDate}}</div>
                    </div>
                    <el-button type="text" class="task-btn" @click="goDeal(taskList[1])">前往处理</el-button>
                  </div>
                  <div v-if="taskList[2]" class="task-reminder">
                    <div>
                      <div class="task-title">{{taskList[2].typeStr}}</div>
                      <div class="task-content">{{taskList[2].content}}</div>
                      <div class="task-time">{{taskList[2].createdDate}}</div>
                    </div>
                    <el-button type="text" class="task-btn" @click="goDeal(taskList[2])">前往处理</el-button>
                  </div>
                </div>
                <div class="all-task">
                  <el-button type="text" @click="handleClick(0)">查看全部任务</el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane label="系统消息" name="second">
                <div class="no-message">暂无系统消息</div>
                <div class="all-task">
                  <el-button type="text" @click="handleClick(1)">查看全部消息</el-button>
                </div>
              </el-tab-pane>
            </el-tabs>
          </template>
          <el-badge :value="taskValue" :max="99" class="item-message"  slot="reference" @click.native="taskShow()" :hidden='badgeShow'>
            <i v-show="showNews" class="news fa fa-bell-o"></i>
          </el-badge>
        </el-popover>
        <el-dropdown>
          <span class="el-dropdown-link name">
            <span class="welcome">欢迎：</span>
            {{account}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="onUserSet">账号设置</el-dropdown-item>
            <!-- 只有平台登录展示账号与安全 -->
            <el-dropdown-item v-if="loginType==1" @click.native="onAccountSecurity">账号安全</el-dropdown-item>
            <el-dropdown-item @click.native="outLogin" divided>
              <i class="fa fa-sign-out"></i>退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="main__body">
      <el-aside class="menu" width="200px" v-show="showMenu">
        <i class="mobile-show close-menu fa fa-times" @click="showMenu=false"></i>
        <el-menu :default-active="$route.path" :unique-opened="true" router>
          <!-- 平台登录 -->
          <div v-if="loginType == 1">
            <el-submenu v-for="(item, index) in menuList" :key="item.name" :index="item.path" v-show="!item.notShow">
              <template slot="title">
                <i :class="[item.icon]"></i>
                <span>{{item.name}}</span>
              </template>
              <el-menu-item v-show="!it.notShow" v-for="(it, idx) in item.group" :key="it.name" :index="it.path"
                @click="onMenu(it.path, (index+1)+''+(idx+1))">{{it.name}}</el-menu-item>
            </el-submenu>
          </div>
          <!-- 商家登录 -->
          <div v-if="loginType == 2">
            <el-menu-item v-show="!it.notShow" v-for="it in merchantsMenu" :key="it.name" :index="it.path">{{it.name}}
            </el-menu-item>
            <el-submenu v-for="(item, index) in tramMenuList" :key="item.name" :index="item.path"
              v-show="!item.notShow">
              <template slot="title">
                <i :class="[item.icon]"></i>
                <span>{{item.name}}</span>
              </template>
              <el-menu-item v-show="!it.notShow" v-for="(it, idx) in item.group" :key="it.name" :index="it.path"
                @click="onMenu(it.path, (index+1)+''+(idx+1))">{{it.name}}</el-menu-item>
            </el-submenu>
          </div>
          <!-- 供应商/经销商登录 -->
          <div v-if="loginType == 3 || loginType == 4">
            <el-menu-item v-show="!it.notShow" v-for="it in merchantsMenu" :key="it.name" :index="it.path">{{it.name}}
            </el-menu-item>
          </div>
        </el-menu>
      </el-aside>
      <el-container class="box">
        <el-main class="app-el-main">
          <i class="mobile-show show-menu fa fa-navicon" v-show="!showMenu" @click="showMenu=true"></i>
          <keep-alive>
            <router-view v-if="!$route.meta.isNotKeep" :noticeFlag="noticeFlag" class="view"></router-view>
          </keep-alive>
          <router-view v-if="$route.meta.isNotKeep" :noticeFlag="noticeFlag" class="view"></router-view>
        </el-main>
        <!-- 消息弹框 -->
        <template v-for="(item,index) in noticeList">
          <div class="homePage-notice-dialog" :key="index">
            <div class="notice-dialog-top">
              <span class="el-dialog__title">您有新的通知</span>
              <button type="button" aria-label="Close" class="el-dialog__headerbtn" @click="noticeDialogClose(index)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </button>
            </div>
            <div class="notice-dialog-content">
              <p class="red">提醒</p>
              <p>{{item.msg}}</p>
              <p class="look-info" @click="noticeDetailFun(index)">查看详情</p>
            </div>
          </div>
        </template>
        <!-- 报警提醒弹框 -->
        <el-dialog title="报警提醒" width="620px" @close="closeAlarm()" :visible.sync="dialogVisibleAlarm"
          :close-on-click-modal="false" :close-on-press-escape="false">
          <div class="alarm-reminder-dialog">
            <img src="../assets/alarmIcon.png">
            <div class="alarm-reminder-title">{{alarm.content}}</div>
            <div class="alarm-reminder-remark">
              <div class="alarm-reminder-remark-row">设备MAC：{{alarm.mac}}</div>
              <div class="alarm-reminder-remark-row">安装位置：{{alarm.location}}</div>
              <div class="alarm-reminder-remark-row">报警时间：{{alarm.alarmTime}}</div>
            </div>
            <div class="alarm-reminder-closeBut">
              <el-button type="primary" @click="dialogVisibleAlarm = false">关闭</el-button>
            </div>
          </div>
        </el-dialog>
        <!-- 播放警报提示音 -->
        <!-- <audio controls="controls" src="../assets/tip.mp3" ref="audio"></audio> -->
        <audio ref="msgTipAudio" hidden controls="controls">
          <source src="../assets/alarmtip.mp3" type="audio/mpeg" />
        </audio>
        <el-footer style="height:46px;" class="footer">深圳市威富视界 版权所有 IPC备案：粤ICP备17007206号-1 版本： {{getVersion()}} 
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  // import mqtt from 'mqtt'
  import Vue from "vue";
  export default {
    data() {
      let _this = this;
      return {
        start: 0, //警报音频播放次数
        playTimes: 3, //警报音频播放次数上限
        dialogVisibleAlarm: false,
        account: "",
        menuList: [],
        timeOut: 30 * 60 * 1000, //设置超时时间： 30分
        currentTime: new Date().getTime(),
        lastTime: '',
        alertFlag: false,
        clearTime: null,
        client: {},
        noticeList: [],
        noticeFlag: '', //是否刷新消息列表页面
        showNews: true, //是否显示消息图标
        showMenu: true,
        alarm: {
          content: 'L2D报警器发生了一次报警！',
          mac: 'E483D3DD7388',
          location: '罗湖中学教学楼5楼',
          alarmTime: '2021-02-26 14:06:08',
        },
        showNewsAlarm: false,
        loginType: 1,
        merchantsMenu: [],
        tramMenuList: [],
        activeName: 'first',
        taskVisible: false,
        taskValue:0,
        badgeShow:true,
        taskList:[{content:'',createdDate:'',typeStr:''},{content:'',createdDate:'',typeStr:''},{content:'',createdDate:'',typeStr:''}]
      };
    },
    activated() {
      console.log("main activated!");
      let _this = this
      _this.userInfo = this.cache.getLS("userInfo");
      _this.loginType = this.cache.getLS("loginType");
      _this.account = this.userInfo.account;
      if (_this.loginType == 1) {
        _this.initMenu(); //平台菜单
      } else if (_this.loginType == 2) {
        _this.initMerchantsMenu(); //商家、供应商登录菜单（因商家、供应商登录无二级菜单，于是需要单独做处理）
      } else if (_this.loginType == 3) {
        _this.initSupplierMenu();
      } else if(_this.loginType == 4) {
        //经销商登录菜单（因无二级菜单，于是需要单独做处理）
        _this.initDealerrMenu();
      }
      // _this.lastTime = new Date().getTime()
      _this.currentTime = new Date().getTime()
      window.document.onmousedown = function () {
        console.log('onmousedown');
        _this.cache.setLS('lastTime', new Date().getTime())
      }
      this.checkTimeout('1'); // 一进页面就判断是否半个小时不操作（关闭页面之后的问题）
      /* 定时器 间隔30秒检测是否长时间未操作页面 */
      this.clearTime = setInterval(this.checkTimeout, 30000)
      this.$nextTick(() => {
        this.$refs.msgTipAudio.load();
        const activeBarElement = document.getElementsByClassName('el-tabs__active-bar')[0]
        activeBarElement.style.width = `56px`
      })
      this.getTaskList()
    },
    deactivated() {
      console.log("main deactivated!");
      clearInterval(this.clearTime);
      this.$destroy();
      // this.client.end();
    },
    mounted() {
      console.log('Main mounted')
      let _this = this;
      _this.$refs.msgTipAudio.addEventListener('ended', _this.limitPlay)
      let userInfo = _this.cache.getLS("userInfo");
      if(userInfo.userMenuInfos) {
        userInfo.userMenuInfos.forEach(item => {
          if (item.menuId == 3) {
            item.childMenus.forEach(it => {
              if (it.menuId == 110) {
                // 当配置有报警记录的权限时 才会显示报警弹框
                this.showNewsAlarm = true;
              }
            })
          }
        })
      }
      let username = '';
      let password = '';
      let hostname = Vue.config.BASE_URL;
      for (let i = 0; i < Vue.config.urlWebSocket.length; i++) {
        if (hostname == Vue.config.urlWebSocket[i][0]) {
          hostname = Vue.config.urlWebSocket[i][1];
          username = Vue.config.urlWebSocket[i][2];
          password = Vue.config.urlWebSocket[i][3];
          break;
        }
      }
      
      _this.client = mqtt.connect(hostname,{
        clientId: `web_${Math.random().toString(16).slice(2)}`,
        username:username,
        password:password
      });
      _this.client.on('connect', function (e) {
        console.log('connect :)',e)
        if( _this.cache.getLS("userInfo")) {
          _this.client.subscribe('WAVE/' + _this.cache.getLS("userInfo")["userId"] + '/MSG/NOTIFY/RESPONSE');
          // 报警记录消息
          _this.client.subscribe('WAVE/L2VOICE/' + _this.cache.getLS("userInfo")["userId"] + '/ALARM/WEB');
          //client.publish('mytopic', 'hello mqtt123');
        }
      });
      _this.client.on("error", function (error) {
        console.log("connect error", error.toString());
      });
      // let len= _this.noticeList.length;
      _this.client.on("message", function (topic, payload) {
        console.log('收到topic = ' + topic + ' 消息: ' + payload.toString());
        if(_this.cache.getLS("userInfo")) {
          if (topic == 'WAVE/' + _this.cache.getLS("userInfo")["userId"] + '/MSG/NOTIFY/RESPONSE') {
            let len = _this.noticeList.length;
            _this.noticeList.push({
              id: len,
              msg: JSON.parse(payload.toString()).content,
            })
          } else {
            if (_this.showNewsAlarm && userInfo) {
              let alarmInfo = JSON.parse(payload.toString());
              _this.alarmTimeVal = _this.format(alarmInfo.alarmTime * 1000);
              // 更新内容
              _this.alarm = {
                content: 'L2D报警器发生了一次报警！',
                mac: alarmInfo.mac,
                location: alarmInfo.location,
                alarmTime: _this.alarmTimeVal,
              }
              _this.rlarmReminder();
              _this.showNotification();
            }
          }
        }
      });
      // if (_this.showNewsAlarm && userInfo) {
      //   setInterval(() => {
      //     this.test()
      //   }, 20000)
      // }
    },
    methods: {
      test() {
        this.rlarmReminder();
        this.showNotification();
        this.alarm.alarmTime = new Date(); //更新消息内容
      },

      checkTimeout(val) {
        let _this = this
        _this.currentTime = new Date().getTime() //更新当前时间
        _this.lastTime = _this.cache.getLS('lastTime')
        if ((_this.currentTime - _this.lastTime > _this.timeOut) && !_this.alertFlag) {
          //判断是否超时
          // console.log("超时");
          _this.alertFlag = true //有弹框
          _this.post(
              'user-service/user/logout', {}, {
                isUseResponse: true
              }
            )
            .then(res => {
              if (res.data.code == 0) {
                _this.cache.delLS('userInfo');
                _this.cache.delLS('token');
                _this.cache.del('loginRole');
                _this.cache.delLS('gatewayRowDate');
                _this.client.end();
              }
            })
          if (val == '1') {
            _this.$router.push("/login");
            return;
          }
          _this.$alert(
            '您已超过30分钟没有操作，请重新登陆。',
            '重新登陆提醒', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                _this.alertFlag = false //没弹框
                _this.$router.push("/login");
              }
            }
          )
        }
      },
      // 初始化菜单
      initMenu() {
        this.menuList = [
          // {
          //   icon: "fa fa-bell-o",
          //   name: "消息管理",
          //   menuId: 86,
          //   path: "/main/notice/list",
          //   group: [{
          //     name: "消息列表",
          //     menuId: 87,
          //     path: "/main/notice/list"
          //   }]
          // },
          // {
          //   icon: "fa fa-home",
          //   name: "首页",
          //   menuId: 1,
          //   path: "main/log/list",
          //   group: []
          // },
          {
            icon: "fa fa-bar-chart",
            name: "数据概览",
            menuId: 165,
            path: "/main/dataOverview/list",
            group: [{
                name: "首页",
                menuId: 166,
                path: "/main/dataOverview/list"
              },
              {
                name: "用户",
                menuId: 167,
                path: "/main/dataUser/list"
              },
              {
                name: "房源",
                menuId: 168,
                path: "/main/dataRoom/list"
              },
              {
                name: "设备",
                menuId: 169,
                path: "/main/dataDevice/list"
              },
              {
                name: "售货",
                menuId: 170,
                path: "/main/dataShop/list"
              },
            ]
          },
          {
            icon: "fa fa-building",
            name: "房管中心",
            menuId: 2,
            path: "/main/apartment/list",
            group: [{
                name: "房源列表",
                menuId: 10,
                path: "/main/apartment/list"
              },
              {
                name: "房型列表",
                menuId: 11,
                path: "/main/house/list"
              },
              {
                name: "房间列表",
                menuId: 12,
                path: "/main/room/list"
              }
            ]
          },
          {
            icon: "fa fa-flash",
            name: "设备管理",
            menuId: 3,
            path: "/main/auth-device/list",
            group: [{
                name: "网关列表",
                menuId: 117,
                path: "/main/gateway/list"
              }, {
                name: "门禁列表",
                menuId: 66,
                path: "/main/AccessControl/list"
              },
              {
                name: "人证机列表",
                menuId: 13,
                path: "/main/auth-device/list"
              },
              {
                name: "绑定记录",
                menuId: 14,
                path: "/main/lock/list"
              },
              {
                name: "分离式门禁列表",
                menuId: 68,
                path: "/main/separateAccessControl/list"
              },
              {
                name: "蓝牙公钥列表",
                menuId: 69,
                path: "/main/BluetoothKey/list"
                // path: "/main/smartMeter/list"
              },
              {
                name: "电子门禁列表",
                menuId: 76,
                path: "/main/electronicAccessControl/list"
                // path: "/main/smartMeter/list"
              },
              {
                name: "智能电表列表",
                menuId: 70,
                path: "/main/smartMeter/list"
              },
              {
                name: "智能水表列表",
                menuId: 71,
                path: "/main/smartWaterMeter/list"
              },
              {
                name: "认证记录",
                menuId: 20,
                path: "/main/authentication/list"
              },
              {
                name: "智能设备",
                menuId: 62,
                path: "/main/ied/list"
              },
              {
                name: "通行记录",
                menuId: 67,
                path: "/main/PassRecord/list"
              },
              {
                name: "报警器列表",
                menuId: 109,
                path: "/main/alarm/list"
              },
              {
                name: "报警记录",
                menuId: 110,
                path: "/main/alarmRecord/list"
              },
              // {
              //   name: "门禁记录",
              //   menuId: 51,
              //   path: "/main/guard/list"
              // }
            ]
          },
          {
            icon: "fa fa-file-text-o",
            name: "订单管理",
            menuId: 4,
            path: "/main/order/list",
            group: [{
                name: "订单列表",
                menuId: 15,
                path: "/main/order/list"
              },
              {
                name: "退款列表",
                menuId: 64,
                path: "/main/Refundlist/list"
              }
            ]
          },
          {
            icon: "fa fa-yen",
            name: "财务管理",
            menuId: 5,
            path: "/main/finance/overview",
            group: [{
                name: "财务概览",
                menuId: 16,
                path: "/main/finance/overview"
              },
              {
                name: "销售统计",
                menuId: 17,
                path: "/main/sale/list"
              },
              {
                name: "对账管理",
                menuId: 18,
                path: "/main/statement/list"
              },
              {
                name: "提款记录",
                menuId: 58,
                path: "/main/record/list"
              },
              {
                name: "转账记录",
                menuId: 59,
                path: "/main/money/list"
              }
            ]
          },
          // {
          //   icon: 'fa fa-user-plus',
          //   name: '房东管理',
          //   menuId: 24,
          //   path: '/main/landlord/list',
          //   group: []
          // },
          {
            icon: "fa fa-group",
            name: "用户管理",
            menuId: 6,
            path: "/main/tenant/list",
            group: [
              {
                name:"用户列表",
                menuId: 160,
                path: "/main/userList/list"
              },
              {
                name: "房客列表",
                menuId: 19,
                path: "/main/tenant/list"
              },
              {
                name: '商户列表',
                menuId: 89,
                path: '/main/landlord/list'
              },
            ]
          },
          {
            icon: "fa fa-book",
            name: "租约管理",
            menuId: 104,
            path: "/main/leaseManagement/list",
            group: [{
              name: "租约列表",
              menuId: 105,
              path: "/main/leaseManagement/list"
            }, 
            {
              name: "退房列表",
              menuId: 65,
              path: "/main/CheckOut/list"
            },
            {
              name: "约看列表",
              menuId: 51,
              path: "/main/date/list"
            },
            {
              name: "批量排房",
              menuId: 134,
              path: "/main/batchrRangement/list"
            },{
              name: "批量退房",
              menuId: 180,
              path: "/main/batchrCheckOut/list"
            }, ]
          },
          // {
          //   icon: "fa fa-tripadvisor",
          //   name: "约看管理",
          //   menuId: 50,
          //   path: "/main/date/list",
          //   group: []
          // },
          {
            icon: "fa fa-unlock-alt",
            name: "访问控制",
            menuId: 25,
            path: "/main/account/list",
            group: [{
                name: "房管员列表",
                menuId: 26,
                path: "/main/account/list"
              },
              {
                name: "权限管理",
                menuId: 27,
                path: "/main/jurisdiction/list"
              }
            ]
          },
          {
            icon: "fa fa-file-text-o",
            name: "合同管理",
            menuId: 52,
            path: "/main/contract/list",
            group: [{
                name: "合同列表",
                menuId: 53,
                path: "/main/contract/list"
              },
              {
                name: "合同模板",
                menuId: 75,
                path: "/main/ContractTemplate/list"
              }
            ]
          },
          {
            icon: "fa fa-list",
            name: "售货管理",
            menuId: 80,
            path: "/main/cargoController/list",
            group: [{
                name: "商家管理",
                menuId: 119,
                path: "/main/merchantManage/merchant/tabList"
              },
              {
                name: "供应商管理",
                menuId: 119,
                path: "/main/merchantManage/supplier/tabList"
              },
              {
                name: "经销商管理",
                menuId: 162,
                path: "/main/merchantManage/dealer/tabList"
              },
              {
                name: "会员列表",
                menuId: 113,
                path: "/main/membersManagement/list"
              },
              {
                name: "货管员列表",
                menuId: 81,
                path: "/main/cargoController/list"
              },
              {
                name: "门店管理",
                menuId: 106,
                path: "/main/groupManagement/list"
              },
              {
                name: "货柜管理",
                menuId: 82,
                path: "/main/containerManagement/list"
              },
              {
                name: "品类管理",
                menuId: 83,
                path: "/main/categoryManagement/list"
              },
              {
                name: "商品管理",
                menuId: 84,
                path: "/main/commodityManagement/list"
              },
              {
                name: "仓库管理",
                menuId: 116,
                path: "/main/storehouse/list"
              },
              {
                name: "报损记录",
                menuId: 112,
                path: "/main/lossReportRecord/list"
              },
              {
                name: "售货订单",
                menuId: 85,
                path: "/main/salesOrder/list"
              },
              {
                name: "实时售卖",
                menuId: 115,
                path: "/main/goodsOnSale/list"
              },
              {
                name: "退款订单",
                menuId: 111,
                path: "/main/refundOrder/list"
              },
              {
                name: "商家收益记录",
                menuId: 121,
                path: "/main/merchantManage/revenueRecords/list"
              },
              {
                name: "供应商收益记录",
                menuId: 140,
                path: "/main/supplierRevenueRecord/list"
              },
              {
                name: "经销商收益记录",
                menuId: 163,
                path: "/main/dealerRevenueRecords/list"
              },
              {
                name: "提现记录",
                menuId: 122,
                path: "/main/merchantManage/witchdrawalRecords/list"
              },
              {
                name: "充值管理",
                menuId: 142,
                path: "/main/rechargeManagement/list"
              },
              {
                name: "发货管理",
                menuId: 120,
                path: "/main/shippingManage/list"
              },
              {
                name: "商家信息",
                menuId: 118,
                path: "/main/merchantManage/merchantInfo/detail"
              },
              {
                name: "开票记录",
                menuId: 107,
                path: "/main/billingRecord/list"
              },
              {
                name: "营销配置",
                menuId: 114,
                path: "/main/marketingConfig/list"
              },
              {
                name: "活动任务管理",
                menuId: 159,
                path: "/main/activeTask/list"
              },
              {
                name: "银行信息管理",
                menuId: 151,
                path: "/main/bankManagement/list"
              },
              {
                name: "意见反馈",
                menuId: 88,
                path: "/main/containerFeedback/list"
              }
            ]
          },
          {
            icon: "fa fa-exchange",
            name: "电车换电",
            menuId: 143,
            path: "/main/tram/user/list",
             group: [{
                name: "用户列表",
                menuId: 144,
                path: "/main/tram/user/list"
              },
              {
                name: "商品管理",
                menuId: 145,
                path: "/main/tram/commodityManagement/list"
              },
              {
                name: "订单列表",
                menuId: 148,
                path: "/main/tram/order/list"
              },
              {
                name: "换电记录",
                menuId: 149,
                path: "/main/tram/powerChangeRecord/list"
              },
              {
                name: "告警记录",
                menuId: 150,
                path: "/main/tram/alarmRecord/list"
              }]
          },
          {
            icon: "fa fa-university",
            name: "校园管理",
            menuId: 172,
            path: "/main/schoolManagement/order/list",
            group:[{
                name: "订单列表",
                menuId: 174,
                path: "/main/schoolManagement/order/list"
            },{
                name: "学校管理",
                menuId: 173,
                path: "/main/schoolManagement/school/list"
            }]
          },
          {
            icon: "fa fa-cog",
            name: "系统管理",
            menuId: 7,
            path: "/main/user/list",
            group: [{
                name: "系统用户",
                menuId: 21,
                path: "/main/user/list"
              },
              {
                name: "房源配置",
                menuId: 177,
                path: "/main/apartment/config"
              },
              {
                name: "参数设置",
                menuId: 101,
                path: "/main/parameter/list"
              },
              {
                name: "广告管理",
                menuId: 102,
                path: "/main/adManagement/list"
              },
              {
                name: "广告位管理",
                menuId: 103,
                path: "/main/adSpaceManagement/list"
              },
              {
                name: "角色管理",
                menuId: 22,
                path: "/main/role/list"
              },
              {
                name: "菜单管理",
                menuId: 23,
                path: "/main/menu/list"
              },
              // {
              //   name: "房东管理",
              //   menuId: 24,
              //   path: "/main/landlord/list"
              // },
              {
                name: "房东信息",
                menuId: 61,
                path: "/main/information/detail/show/0"
              },
              {
                name: "学校信息",
                menuId: 176,
                path: "/main/schoolInfo/list"
                },
{
                name: "导出中心",
                menuId: 171,
                path: "/main/exportCenter/list"
              },
              {
                menuId: 8,
                name: "登录日志",
                path: "/main/log/list"
              },
              {
                menuId: 108,
                name: "操作日志",
                path: "/main/operationLog/list"
              },
              {
                menuId: 63,
                name: "意见反馈",
                path: "/main/feedback/list"
              },

            ]
          }
        ];
        // 房东的数据概览
        if(this.cache.getLS("userInfo")["roleType"] == "2") {
          this.menuList.forEach(item => {
            if(item.menuId == 165) {
              item.path = '/main/landlorDataOverview/list'
              item.group = [{
                name: "首页",
                menuId: 166,
                path: "/main/landlorDataOverview/list"
              },]
            }
          })
          
          if(this.cache.getLS("userInfo")["category"] == 0) {
            this.menuList.forEach(item =>{
              // 普通房东不展示学校信息
              if(item.menuId == 7) {
                item.group.forEach((it,idx) =>{
                  if(it.menuId == 176) {
                    it.notShow = true;
                  }
                })
              }
              // 普通房东不展示批量退房列表
              if(item.menuId == 104) {
                item.group.forEach((it,idx) =>{
                  if(it.menuId == 180) {
                    it.notShow = true;
                  }
                })
              }
            })
          }
          
        }
        let menuGetList = this.userInfo.userMenuInfos;
        let allIdList = [];
        let that = this;
        if (menuGetList && menuGetList.length) {
          menuGetList.forEach(item => {
            allIdList.push(item.menuId);
            if (item.childMenus.length) {
              item.childMenus.forEach(data => {
                allIdList.push(data.menuId);
                // if (data.menuId == 87) {
                //   that.showNews = true
                //   that.badgeShow = false
                // }
              });
            }
          });
        }
        this.menuList.forEach(item => {
          if (allIdList.indexOf(item.menuId) == -1) {
            item.notShow = true;
          }
          item.group.forEach(data => {
            if (allIdList.indexOf(data.menuId) == -1) {
              data.notShow = true;
            }
          });
        });
      },
      // 初始化商家登录显示的菜单
      initMerchantsMenu() {
        if (this.loginType == 2) {
          this.merchantsMenu = [{
              name: "商家信息",
              menuId: 118,
              path: "/main/merchantManage/merchantInfo/detail"
            },
            {
              name: "会员列表",
              menuId: 113,
              path: "/main/membersManagement/list"
            },
            {
              name: "货管员列表",
              menuId: 81,
              path: "/main/cargoController/list"
            }, {
              name: "门店管理",
              menuId: 106,
              path: "/main/groupManagement/list"
            },
            {
              name: "货柜管理",
              menuId: 82,
              path: "/main/containerManagement/list"
            },
            {
              name: "商品品类",
              menuId: 83,
              path: "/main/categoryManagement/list"
            },
            {
              name: "商品管理",
              menuId: 84,
              path: "/main/merchantCommodityManagement/list"
            },
            {
              name: "仓库管理",
              menuId: 116,
              path: "/main/storehouse/list"
            },
            {
              name: "报损记录",
              menuId: 112,
              path: "/main/lossReportRecord/list"
            },
            {
              name: "售货订单",
              menuId: 85,
              path: "/main/salesOrder/list"
            },
            {
              name: "实时售卖",
              menuId: 115,
              path: "/main/goodsOnSale/list"
            },
            {
              name: "退款订单",
              menuId: 111,
              path: "/main/refundOrder/list"
            },
            {
              name: "收益记录",
              menuId: 121, //商家收益记录
              path: "/main/merchantManage/revenueRecords/list"
            },
            {
              name: "提现记录",
              menuId: 122,
              path: "/main/merchantManage/witchdrawalRecords/list"
            },
            {
              name: "充值记录",
              menuId: 141,
              path: "/main/merchantManage/merchantRecharge/list"
            },
            {
              name: "开票记录",
              menuId: 107,
              path: "/main/billingRecord/list"
            },
            {
              name: "营销配置",
              menuId: 114,
              path: "/main/marketingConfig/list"
            },
            {
                name: "活动任务管理",
                menuId: 114,//虚设id 待对接
                path: "/main/activeTask/list"
            },
            {
              name: "意见反馈",
              menuId: 88,
              path: "/main/containerFeedback/list"
            }
          ];
          this.tramMenuList = [{
            name: "电车换电",
            menuId: 143,
            path: "/main/tram/user/list",
            group: [{
                name: "用户列表",
                menuId: 144,
                path: "/main/tram/user/list"
              },
              {
                name: "商品管理",
                menuId: 145,
                path: "/main/tram/commodityManagement/list"
              },
              {
                name: "订单列表",
                menuId: 148,
                path: "/main/tram/order/list"
              },
              {
                name: "换电记录",
                menuId: 149,
                path: "/main/tram/powerChangeRecord/list"
              },
              {
                name: "告警记录",
                menuId: 150,
                path: "/main/tram/alarmRecord/list"
              }
            ]
          }];
        }

        let merchantsMenuIdList = [];
        let merchantsMenuList = [];
        let tramMenuList = [];
        let tramMenuIdList = [];

        let phoneNum = localStorage.getItem('phoneNum');
        this.userInfo.userMenuInfos.forEach(item => {
          if (item.menuId == 80) {
            merchantsMenuList = item.childMenus;
          }
          if (item.menuId == 143) {
            tramMenuList[0] = item;
          }
        })
        if (merchantsMenuList && merchantsMenuList.length > 0) {
          merchantsMenuList.forEach(item => {
            merchantsMenuIdList.push(item.menuId);
          })
        }

        if (tramMenuList && tramMenuList.length > 0) {
          tramMenuList.forEach(item => {
            tramMenuIdList.push(item.menuId);
            if (item.childMenus && item.childMenus.length > 0) {
              item.childMenus.forEach(data => {
                tramMenuIdList.push(data.menuId);
              })
            }
          })
        }
        
        // 手机号码为1时该商家未审核，不展示除商家信息外的其他信息
        if(phoneNum == '1') {
          this.tramMenuList = [];
        }else {
          this.tramMenuList.forEach(item => {
            if (tramMenuIdList.indexOf(item.menuId) == -1) {
              item.notShow = true;
            }
            item.group.forEach(data => {
              if (tramMenuIdList.indexOf(data.menuId) == -1) {
                data.notShow = true;
              }
            });
          });
        }
        
        this.merchantsMenu.forEach(item => {
          if (merchantsMenuIdList.indexOf(item.menuId) == -1) {
            item.notShow = true;
          }
          if (phoneNum == '1') { // 手机号码为1时该商家未审核，不展示除商家信息外的其他信息
            if (item.menuId !== 118) {
              item.notShow = true;
            }
          }
        })
      },
      initSupplierMenu() {
        this.merchantsMenu = [{
          name: "供应商信息",
          menuId: 139,
          path: "/main/merchantManage/merchantInfo/detail"
        }, {
          name: "出货记录",
          menuId: 1001, //假的id
          path: "/main/supplierShipment/list"
        }, {
          name: "商品管理",
          menuId: 84,
          path: "/main/supplierCommodityManagement/list"
        }, {
          name: "提现记录",
          menuId: 122,
          path: "/main/merchantManage/witchdrawalRecords/list"
        }, {
          name: "收益记录",
          menuId: 140,
          path: "/main/supplierRevenueRecord/list"
        }]
        this.merchantsMenu.forEach(item => {
          if (this.userInfo.phoneNum == 1) {
            if (item.menuId !== 139) {
              item.notShow = true;
            }
          }
        })
      },
      initDealerrMenu() {
        this.merchantsMenu = [{
          name: "经销商信息",
          menuId: 164,
          path: "/main/merchantManage/merchantInfo/detail"
        },
        {
          name: "商品品类",
          menuId: 83,
          path: "/main/categoryManagement/list"
        },
        {
          name: "商品管理",
          menuId: 84,
          path: "/main/dealerCommodityManagement/list"
        },
        {
          name: "售货订单",
          menuId: 85,
          path: "/main/salesOrder/list"
        },
        {
          name: "经销记录",
          menuId: 161,
          path: "/main/dealerDistributionRecords/list"
        },
        {
          name: "收益记录",
          menuId: 163,
          path: "/main/dealerRevenueRecords/list"
        },
        {
          name: "提现记录",
          menuId: 122,
          path: "/main/merchantManage/witchdrawalRecords/list"
        }]
        let merchantsMenuIdList = [];
        let merchantsMenuList = [];
        this.userInfo.userMenuInfos.forEach(item => {
          if (item.menuId == 80) {
            merchantsMenuList = item.childMenus;
          }
        })
        if (merchantsMenuList && merchantsMenuList.length > 0) {
          merchantsMenuList.forEach(item => {
            merchantsMenuIdList.push(item.menuId);
          })
        }

        this.merchantsMenu.forEach(item => {
          if (merchantsMenuIdList.indexOf(item.menuId) == -1) {
            item.notShow = true;
          }
          if (this.userInfo.phoneNum == 1) {
            if (item.menuId !== 164) {
              item.notShow = true;
            }
          }
        })
      },
      onMenu(path, idx) {
        this.$router.push(path);
      },
      onUserSet() {
        this.$router.push("/main/userSet/main");
      },
      onAccountSecurity() {
        this.$router.push("/main/accountSecurity/detail");
      },
      outLogin() {
        this.$confirm("确认退出当前用户", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.post(
              "user-service/user/logout", {}, {
                isUseResponse: true
              }
            ).then(res => {
              if (res.data.code == 0) {
                this.$message({
                  message: "退出成功！",
                  type: "success"
                });
                this.cache.delLS("userInfo");
                this.cache.delLS("token");
                this.cache.delLS("loginType");
                this.$router.push("/login");
                this.cache.del('loginRole');
                this.cache.delLS('gatewayRowDate');
                this.client.end();
                // location.reload();
              }
            });
          })
          .catch(function () {});
      },
      noticeDialogClose(index) {
        this.noticeList.splice(index, 1);
      },
      noticeDetailFun(index) {
        this.noticeList.splice(index, 1);

        if (this.$route.path == '/main/notice/list') {
          this.noticeFlag = index
        } else {
          this.$router.push('/main/notice/list');
        }

      },
      closeMenu() {
        this.showMenu = false
      },
      // 报警提醒
      rlarmReminder() {
        this.dialogVisibleAlarm = true;

      },
      // 关闭报警提醒
      closeAlarm() {
        this.dialogVisibleAlarm = false;
        this.$nextTick(() => {
          this.$refs.msgTipAudio.pause();
        })
      },
      // 小窗口的报警提醒
      sendNotification() {
        let _this = this;
        console.log('L2D报警器发生了一次报警！')
        var n = new Notification("报警提醒：", {
          body: 'L2D报警器发生了一次报警！' + _this.alarmTimeVal,
          icon: 'https://pic1.zhuanstatic.com/zhuanzh/50b6ffe4-c7e3-4317-bc59-b2ec4931f325.png',
          tag: 'soManyNotification', //只显示一个信息提醒
          // requireInteraction: true,//通知应保持有效，直到用户点击或关闭它
        })
        n.onshow = function () {
          // _this.$nextTick(() => {
          //   _this.$refs.msgTipAudio.currentTime = 0; //从0开始播放
          //   _this.$refs.msgTipAudio.play();
          // })
          _this.limitPlay();
        }
        n.onclose = function () {
          _this.$nextTick(() => {
            _this.$refs.msgTipAudio.pause();
          })
        }
      },
      showNotification() {
        let _this = this;
        _this.start = 0;
        console.log('window.Notification.permission:', window.Notification.permission)
        if (window.Notification.permission == "granted") { // 判断是否有权限   granted--允许通知
          _this.sendNotification();
        } else if (window.Notification.permission != "denied") { //未禁止  denied--禁止通知
          // _this.$nextTick(() => {
          //   _this.$refs.msgTipAudio.currentTime = 0; //从0开始播放
          //   _this.$refs.msgTipAudio.play();
          // })
          _this.limitPlay();
          window.Notification.requestPermission(function (permission) { // 没有权限发起请求
            _this.sendNotification();
          });
        }
      },
      limitPlay() {
        this.start++;
        console.log('次数限制：', this.start);
        if (this.start <= this.playTimes) {
          this.$nextTick(() => {
            this.$refs.msgTipAudio.currentTime = 0; //从0开始播放
            this.$refs.msgTipAudio.play();
          })

        } else {
          this.$nextTick(() => {
            this.$refs.msgTipAudio.pause();
          })
        }
      },
      taskShow(){
        this.taskVisible = !this.taskVisible;
        this.getTaskList()
      },
      handleClick(val){
        this.$router.push({path:'/main/notice/list',
          query: {
            currentTab: val,
          }});
        this.taskVisible = false
        this.allRead()
      },
      getTaskList(){
        let dto = {
            pageNumber: 1,
            pageSize: 3,
            isRead:'N'
        }
        this.post("user-service/message/queryTaskMessage",dto,{
          isUseResponse: true,
        }).then(res => {
          res.data.data.forEach(item=>{
            if(item.type == '42') {
              item.typeStr = '用户注销审核'
            }else if(item.type == '43') {
              item.typeStr = '系统意见反馈'
            }else if(item.type == '44') {
              item.typeStr = '售货意见反馈'
            }else if(item.type == '45') {
              item.typeStr = '提现审核'
            }else if(item.type == '46') {
              item.typeStr = '审核商家'
            }else if(item.type == '47') {
              item.typeStr = '审核经销商'
            }else if(item.type == '48') {
              item.typeStr = '审核供应商'
            }else if(item.type == '49') {
              item.typeStr = '审核房东'
            }else if(item.type == '50') {
              item.typeStr = '导出任务'
            }else{
              item.typeStr = '其他任务'
            }
          })
          this.taskList = res.data.data
          this.taskValue = res.data.total
          if(this.taskValue == 0){
            this.badgeShow = true
          }else{
            this.badgeShow = false
          }
        })
      },
      goDeal(val){
        let dto = {
            id:val.id
        }
        this.post("user-service/message/updateTaskMessage",dto,{
          isUseResponse: true,
        }).then(res => {
          if(val.type == '42') {
            this.$router.push('/main/userList/list');
          }else if(val.type == '43') {
            this.$router.push('/main/feedback/list');
          }else if(val.type == '44') {
            this.$router.push('/main/containerFeedback/list');
          }else if(val.type == '45') {
            this.$router.push('/main/merchantManage/witchdrawalRecords/list');
          }else if(val.type == '46') {
            this.$router.push('/main/merchantManage/merchant/tabList');
          }else if(val.type == '47') {
            this.$router.push('/main/merchantManage/dealer/tabList');
          }else if(val.type == '48') {
            this.$router.push('/main/merchantManage/supplier/tabList');
          }else if(val.type == '49') {
            this.$router.push('/main/landlord/list');
          }else if(val.type == '50') {
            this.$router.push('/main/exportCenter/list');
          }
          this.taskVisible = false;
          this.taskValue = this.taskValue-1
          if(this.taskValue == 0){
            this.badgeShow = true
          }else{
            this.badgeShow = false
          }
        })
      },
      allRead() {
        let dto = {
            isAll:true
        }
        this.post("user-service/message/updateTaskMessage",dto,{
          isUseResponse: true,
        }).then(res => {
          this.badgeShow = true
        })
      }
    }
  };
</script>
<style lang="scss">
  .main {
    height: 100%;

    .main__body {
      height: calc(100vh - 60px);
    }

    .header {
      position: relative;
      background-color: #30BAC1;

      .logo {
        img {
          margin-top: 15px;
        }
      }

      .user {
        position: absolute;
        top: 0;
        right: 20px;
        padding-top: 19px;
        height: 100%;

        .news {
          margin-right: 5px;
          font-size: 18px;
          color: #ffffff;
          cursor: pointer;
        }

        .welcome {
          color: #ffffff;
        }

        .name {
          color: #ffffff;
          cursor: pointer;
        }

      }
    }

    .menu {
      border-right: solid 1px #dcdfe6;

      .el-submenu__title {
        i {
          margin-right: 8px;
          margin-left: 8px;
          width: 18px;
          font-size: 18px;
          text-align: center;
        }
      }

      .el-menu-item {
        min-width: auto;
        text-indent: 13px;
      }
    }

    .app-el-main {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .box {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 60px);
    }

    .view {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
    }

    .footer {
      line-height: 45px;
      text-align: center;
      font-size: 12px;
      color: #c0c4cc;
      border-top: solid 1px #dcdfe6;
    }

    .homePage-notice-dialog {
      position: fixed;
      bottom: 16px;
      right: 16px;
      width: 410px;
      height: 280px;
      background: #ffffff;
      border: 1px solid rgba(158, 161, 170, 0.54);
      // box-shadow: 0px 0px 9px 1px rgba(158, 161, 170, 0.54);
      z-index: 8889;

      .notice-dialog-top {
        width: 410px;
        height: 39px;
        background: #30BAC1;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 39px;
        text-align: center;
      }

      .notice-dialog-content {
        padding: 15px 25px;
        color: #333333;
        font-size: 15px;
        word-break: break-all;

        .red {
          color: #ff3c4a;
        }

        .look-info {
          text-align: right;
          color: #30BAC1;
          cursor: pointer;
        }
      }

      /deep/.el-icon-close:before {
        /* text-align: right; */
        position: absolute !important;
        right: 5px !important;
        top: -8px !important;
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #ffffff;
      }

    }

    .mobile-show {
      display: none;
    }
  }

  .el-menu-item.is-active {
    color: #30BAC1;
  }

  .alarm-reminder-dialog {
    text-align: center;

    img {
      width: 110px;
    }

    .alarm-reminder-title {
      font-size: 24px;
      font-weight: bold;
      margin: 10px 0
    }

    .alarm-reminder-remark {
      text-align: left;
      display: inline-block;
    }

    .alarm-reminder-remark-row {
      margin: 10px 0
    }

    .alarm-reminder-closeBut {
      margin-top: 30px;
    }
  }

  >>>.el_main {
    padding: 0px;
  }

  // 调整输入框和按钮的高度
  .el-form-item__content {
    line-height: 34px;
  }

  .el-input__inner {
    height: 35px;
    line-height: 35px;
  }

  .el-input__icon {
    line-height: 35px;
  }

  .el-button.is-round {
    padding: 9px 23px;
  }

  .el-button--medium {
    padding: 9px 20px;
  }

  .el-input-number {
    line-height: 32px;
  }

  .el-button {
    padding: 9px 20px;
  }

  .el-date-editor .el-range__icon {
    line-height: 28px;
  }

  .main .view {
    top: 8px;
    bottom: 10px;
  }

  .item-message{
    margin-right: 25px;
  }

  .task-reminder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E4E7ED;
    padding: 8px 0;

    .task-title{
      margin: 5px;
      font-size: 16px;
      color: #000;
    }
    .task-content{
      margin: 5px;
      font-size: 14px;
      color: #333;
      width: 400px;
    }
    .task-time{
      margin: 5px;
      font-size: 12px;
      color: #999;
    }
    .task-btn{
      color: #30BAC1;
    }
  }

  .all-task{
    color: #30BAC1;
    text-align: center;
    font-size: 16px;
    margin: 10px 0 5px;
  }

  .close-btn{
    display: flex;
    justify-content: flex-end;
    position: relative;
    .btn{
      color: #333;
      padding: 0;
      font-size: 14px;
    }
  }

  .no-message{
    text-align: center;
    border-bottom: 1px solid #E4E7ED;
    padding: 30px 0;
  }

  @media only screen and (max-width: 500px) {
    .main .mobile-show {
      display: block;
      color: #919191;
    }

    .main .close-menu {
      font-size: 25px;
      margin-left: 150px;
      margin-top: 10px;
    }

    .main .show-menu {
      font-size: 25px;
    }

    .main .view {
      top: 50px;
    }
  }
</style>